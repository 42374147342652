import { AccountCmeCredit } from '../schema/cme/accountCmeCredit';

export function getCmeCreditsByTopic(credits: AccountCmeCredit[]) {
  const allEpisodeTags = credits.flatMap(
    accountCredit => accountCredit.episode.tags
  );

  const mergedEpisodeTags: Record<string, number> = {};

  allEpisodeTags.forEach(({ tag, credits: tagCredits }) => {
    mergedEpisodeTags[tag] = (mergedEpisodeTags[tag] || 0) + tagCredits;
  });

  return Object.entries(mergedEpisodeTags)
    .map(([topic, totalCredits]) => ({
      topic,
      credits: totalCredits
    }))
    .sort((a, b) => a.topic.localeCompare(b.topic));
}
