import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Loader from '../Loader';
import { episodesSlice, loadEpisode } from '../../store/episodes';
import NotFound from '../NotFound';
import { getAccountType } from '../../lib/getAccountType';
import ChapterPage from './ChapterPage';
import { HDChapterPage } from '../HDChapterPage/HDChapterPage';
import { useSelector } from '../../lib/hooks';
import { accountSlice } from '../../store/account';
import { useUrgentCare } from '../../hooks/useUrgentCare';
import { getEpisodePath } from '../../lib/getEpisodePath';
import { reportOpenEpisodeAnalytics } from '../../analytics/reportOpenEpisodeAnalytics';
import { loadCmeFaculty } from '../../store/cmeFaculties';
import { loadCmeEpisodeCredit } from '../../store/cmeEpisodesCredit';

interface ChapterPageWrapperComponentProps {
  isDesktop: boolean;
}

const ChapterPageWrapper = ({
  isDesktop
}: ChapterPageWrapperComponentProps) => {
  const dispatch = useDispatch();
  const {
    episodeShortname,
    chapterShortname,
    playlistShortnames: playlistShortnamesParams,
    episodeType
  } = useParams<{
    playlistShortnames?: string;
    episodeShortname: string;
    chapterShortname?: string;
    episodeType?: string;
  }>();
  const location = useLocation();
  const playlistShortnames = playlistShortnamesParams?.split('/') || [];
  const [hasLoadEpisodeDispatched, setHasLoadEpisodeDispatched] =
    useState(false);

  const episodeState = useSelector(
    state => state[episodesSlice.name].documents[episodeShortname]
  );
  const firebaseState = useSelector(state => state.firebase);
  const accountState = useSelector(state => state[accountSlice.name]);
  const episode = episodeState?.data;
  const isLoading =
    !hasLoadEpisodeDispatched ||
    episodeState?.isLoading ||
    firebaseState.isLoading;
  const hasError = episodeState?.hasError;
  const episodeError = episodeState?.error;
  const accountType = getAccountType(accountState);
  const isUrgentCare = useUrgentCare() || episode?.type === 'ucmaximus';

  useEffect(() => {
    if (!accountState.isLoading) {
      dispatch(loadEpisode(episodeShortname));
      setHasLoadEpisodeDispatched(true);
    }
  }, [episodeShortname, loadEpisode, dispatch, accountState]);

  const chapter =
    episode &&
    episode.chapters.find(item => item.shortname === chapterShortname);

  const history = useHistory();
  useEffect(() => {
    if (episode && !chapterShortname) {
      // route structure /episode/:episodeShortname or /:episodeType/playlist/episode/:episodeShortname
      const defaultChapter =
        accountType && accountType !== 'free'
          ? episode.chapters[0]
          : episode.chapters.find(episodeChapter => episodeChapter.isFree) ||
            episode.chapters[0];
      if (episodeType === 'c3' && playlistShortnames.length) {
        history.replace(
          `/${episodeType}/playlist/${playlistShortnames.join(
            '/'
          )}/episode/${episodeShortname}/${defaultChapter.shortname}`
        );
      } else {
        history.replace(
          getEpisodePath(
            isUrgentCare,
            `/${episodeShortname}/${defaultChapter.shortname}`
          )
        );
      }
    }
  }, [episode, chapterShortname, episodeShortname, history, isUrgentCare]);

  useEffect(() => {
    if (chapter !== undefined) {
      dispatch(loadCmeFaculty(chapter.id));
    }
  }, [chapter]);

  useEffect(() => {
    if (
      episode !== undefined &&
      !accountState.isLoading &&
      accountType !== 'student'
    ) {
      dispatch(
        loadCmeEpisodeCredit({
          episodeId: episode.id,
          isSignedIn: accountState.account !== null
        })
      );
    }
  }, [episode, accountState]);

  useEffect(() => {
    if (episode === undefined) {
      return;
    }

    reportOpenEpisodeAnalytics({
      id: episode.id,
      shortname: episode.shortname,
      title: episode.title,
      type: episode.type,
      chapterCount: episode.chapters.length
    });
  }, [episode]);

  if (isLoading || (episode && !chapterShortname)) {
    return <Loader />;
  }

  if (!episode || !chapter || hasError) {
    let reason = 'unknown';
    if (!episode) {
      reason = `episode not found: ${episodeShortname}`;
    } else if (!chapter) {
      reason = `chapter not found: ${chapterShortname}`;
    }
    if (hasError) {
      const errorMessage =
        typeof episodeError === 'string' ? episodeError : episodeError?.message;
      if (errorMessage) {
        reason += ` due to error: ${errorMessage}`;
      }
    }

    return (
      <NotFound
        sourcePath={location.pathname}
        component="ChapterPageWrapper"
        reason={reason}
        extra={{
          episodeShortname,
          chapterShortname,
          playlistShortnames,
          episodeType,
          accountType,
          accountLoading: accountState?.isLoading,
          accountId: accountState?.account?.id,
          accountError: accountState?.hasError,
          firebaseError: firebaseState?.error
        }}
      />
    );
  }

  if (isUrgentCare && episode.type !== 'ucmaximus' && episode.type !== 'hd') {
    return (
      <NotFound
        sourcePath={location.pathname}
        component="ChapterPageWrapper"
        reason={`Is Urgent Care but episode type is ${episode.type}`}
      />
    );
  }

  return episode.type === 'hd' ? (
    <HDChapterPage
      playlistShortnames={playlistShortnames}
      chapter={chapter}
      episode={episode}
      isDesktop={isDesktop}
      isUrgentCare={isUrgentCare}
    />
  ) : (
    <ChapterPage
      playlistShortnames={playlistShortnames}
      isDesktop={isDesktop}
      chapter={chapter}
      episode={episode}
    />
  );
};

export default ChapterPageWrapper;
