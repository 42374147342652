import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { CmeEvaluationsQuestions } from './CmeEvaluationsQuestions';
import { AccountCmeCredit } from '../../schema/cme/accountCmeCredit';
import { useSelector } from '../../lib/hooks';
import {
  loadEvaluations,
  resetEvaluationResponses,
  updateCmeEvaluationQuestionResponse
} from '../../store/cmeEvaluations';
import {
  EvaluationSubmissionStatus,
  useCmeEvaluationsSubmit
} from '../../hooks/useCmeEvaluationsSubmit';
import { hasAnsweredEvaluationQuestions } from '../../lib/hasAnsweredEvaluationQuestions';
import { getApiUrl } from '../../lib/fetchApi';
import { reportError } from '../../lib/reportError';
import Loader from '../Loader';
import { Modal, ModalStickyFooter } from '../Modal';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { EpisodeCreditsTable } from './EpisodeCreditsTable';
import { TopicCreditsTable } from './TopicCreditsTable';
import { CmeEvaluationResponse } from '../../schema/cme/cmeEvaluationResponse';
import { hasTouchScreen } from '../../lib/hasTouchScreen';
import { reportCmeEvaluationFlowSteppedAnalytics } from '../../analytics/reportCmeEvaluationFlowSteppedAnalytics';
import { CurrentStep } from '../../lib/cmeEvaluationSteps';
import { reportCmeEvaluationSubmittedAnalytics } from '../../analytics/reportCmeEvaluationSubmittedAnalytics';
import { reportCmePrintedAnalytics } from '../../analytics/reportCmePrintedAnalytics';

import './CmeEvaluationFlow.scss';

const scrollToTop = (
  ref: React.RefObject<HTMLElement>,
  shouldScroll: boolean
) => {
  useEffect(() => {
    if (shouldScroll && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [ref, shouldScroll]);
};

export function hasAnsweredResponse(response: CmeEvaluationResponse): boolean {
  const questionResponses = response.questionResponse;

  // Check if at least one question has a non-empty response
  return Object.values(questionResponses).some(questionResponse => {
    const hasNumericValue = questionResponse.numeric_value !== null;
    const hasTextValue =
      questionResponse.text_value !== null &&
      questionResponse.text_value !== '';
    const hasOptionId =
      questionResponse.evaluation_question_option_id.length > 0;
    return hasNumericValue || hasTextValue || hasOptionId;
  });
}

interface CmeEvaluationFlowProps {
  evaluationCredits: AccountCmeCredit[];
  currentStep: CurrentStep;
  setEvaluationStep: (S: React.SetStateAction<CurrentStep>) => void;
  handleEvaluationFlowCompletion: () => void;
  handleCreditsClaimed: (creditIds: number[]) => void;
  handleClose: () => void;
}

type tabPanel = 'credits' | 'episodes';

const getCopy = (step: CurrentStep, totalCredits: number) => {
  switch (step) {
    case CurrentStep.CONFIRM:
      return {
        title: `Claiming ${totalCredits} credits`,
        paragraph: "Are these all of the credits you'd like to claim today?",
        back: 'Cancel',
        next: 'Start Evaluation'
      };
    case CurrentStep.QUESTIONS:
      return {
        title: 'Evaluation',
        paragraph: 'To complete your evaluation, answer the questions below.',
        back: 'Back',
        next: 'Complete Evaluation'
      };
    case CurrentStep.COMPLETE:
      return {
        title: 'Get Certificate',
        subtitle: `You've successfully claimed ${totalCredits} credits!`,
        paragraph:
          'You can download the certificate with these credits now, or come back and download them later.',
        back: 'Return to CME Page',
        next: 'Get Certificate'
      };
    default:
      reportError(`Unhandled value ${step}`);
      throw new Error(`Unhandled value ${step}`);
  }
};

export function CmeEvaluationFlow({
  evaluationCredits,
  currentStep,
  setEvaluationStep,
  handleEvaluationFlowCompletion,
  handleClose,
  handleCreditsClaimed
}: CmeEvaluationFlowProps) {
  const [selectedTab, changeSelectedTab] = useState<tabPanel>('credits');

  const {
    cmeEvaluations,
    cmeEvaluationsResponses,
    isLoading: isCmeEvaluationLoading
  } = useSelector(state => state.cmeEvaluations);

  const { submissionState, submitEvaluation } = useCmeEvaluationsSubmit();
  const dispatch = useDispatch();
  const { modernizeCmeTable } = useFlags();
  const isMobile = useMediaQuery({ query: '(max-width: 48rem)' });
  const totalCredits = evaluationCredits.reduce(
    (currentTotal, credit) => currentTotal + credit.overallCreditHours,
    0
  );
  const copy = getCopy(currentStep, totalCredits);

  const allQuestionsAnswered = useMemo(
    () =>
      cmeEvaluations !== null
        ? hasAnsweredEvaluationQuestions(
            cmeEvaluations[0],
            cmeEvaluationsResponses[0]
          )
        : false,
    [cmeEvaluations, cmeEvaluationsResponses]
  );

  const handleBack = (step: string) => {
    // Typescript will ensure we have a step matching one of these cases
    // eslint-disable-next-line default-case
    switch (step) {
      case CurrentStep.CONFIRM:
      case CurrentStep.COMPLETE:
        reportCmeEvaluationFlowSteppedAnalytics({
          actionStep: 'close',
          actionText: copy.back,
          evaluationStep: currentStep
        });
        setEvaluationStep(CurrentStep.NONE);
        break;
      case CurrentStep.QUESTIONS:
        reportCmeEvaluationFlowSteppedAnalytics({
          actionStep: 'back',
          actionText: copy.back,
          evaluationStep: currentStep
        });
        setEvaluationStep(CurrentStep.CONFIRM);
        break;
    }
    return null;
  };

  const handleNext = async (step: CurrentStep) => {
    reportCmeEvaluationFlowSteppedAnalytics({
      actionStep: 'forward',
      actionText: copy.next,
      evaluationStep: currentStep
    });
    // Typescript will ensure we have a step matching one of these cases
    // eslint-disable-next-line default-case
    switch (step) {
      case CurrentStep.CONFIRM:
        setEvaluationStep(CurrentStep.QUESTIONS);
        break;
      case CurrentStep.QUESTIONS: {
        const creditIds = evaluationCredits.map(credit => credit.id);
        const success = await submitEvaluation(
          cmeEvaluationsResponses,
          creditIds,
          modernizeCmeTable ? 'v2' : undefined
        );
        if (success) {
          reportCmeEvaluationSubmittedAnalytics({
            creditIds,
            episodeIds: evaluationCredits.map(credit => credit.episode.id)
          });
          setEvaluationStep(CurrentStep.COMPLETE);
          handleCreditsClaimed(creditIds);
        }
        break;
      }
      case CurrentStep.COMPLETE:
        handleEvaluationFlowCompletion();
        break;
    }
  };

  const handleEvaluationFlowClose = () => {
    reportCmeEvaluationFlowSteppedAnalytics({
      actionStep: 'close',
      evaluationStep: currentStep
    });
    handleClose();
  };

  // load evaluation on questions step
  useEffect(() => {
    if (
      currentStep !== CurrentStep.QUESTIONS ||
      evaluationCredits.length === 0
    ) {
      return;
    }
    dispatch(loadEvaluations(evaluationCredits.map(credit => credit.id)));
  }, [currentStep, evaluationCredits, loadEvaluations, dispatch]);

  const scrollableContainerRef = useRef(null);
  scrollToTop(scrollableContainerRef, currentStep !== CurrentStep.QUESTIONS);

  return (
    <Modal
      isOpen
      handleClose={
        // Stops the modal from closing while the evaluation submission is happening
        submissionState === EvaluationSubmissionStatus.LOADING
          ? () => undefined
          : handleEvaluationFlowClose
      }
      modalClassName="evaluation-flow-modal"
      sectionClassName="evaluation-flow-modal__section"
      title="Claim your CME"
      ref={scrollableContainerRef}
      footer={
        <ModalStickyFooter
          primaryAction={
            currentStep === CurrentStep.COMPLETE &&
            modernizeCmeTable !== undefined ? (
              <a
                className="global-button"
                data-variant="primary"
                // Opening this URL in development is not possible
                // due to the inability to pass a required reference token header.
                // For testing, please use Berna, as it allows opening the link.
                href={getApiUrl(
                  `${
                    modernizeCmeTable ? 'v2/' : ''
                  }cme_certificates?creditsIds=${evaluationCredits
                    .map(credit => credit.id)
                    .join(',')}`
                )}
                rel="noopener noreferrer"
                onClick={() => {
                  reportCmePrintedAnalytics({
                    creditIds: evaluationCredits.map(credit => credit.id),
                    episodeIds: evaluationCredits.map(
                      credit => credit.episode.id
                    )
                  });
                  handleNext(currentStep);
                }}
                download={
                  isMobile && hasTouchScreen()
                    ? `certificate-${
                        new Date().toISOString().split('T')[0]
                      }.pdf`
                    : undefined
                }
              >
                {copy.next}
              </a>
            ) : (
              <button
                disabled={
                  (currentStep === CurrentStep.QUESTIONS &&
                    !allQuestionsAnswered) ||
                  modernizeCmeTable === undefined
                }
                type="button"
                className="global-button"
                data-variant="primary"
                onClick={() => handleNext(currentStep)}
              >
                {copy.next}
              </button>
            )
          }
          secondaryAction={
            <button
              type="button"
              className="global-button"
              data-variant="tertiary"
              onClick={() => handleBack(currentStep)}
            >
              {copy.back}
            </button>
          }
        />
      }
    >
      <div className="global-is-stack evaluation-flow-modal__section-grid">
        <header className="evaluation-flow-modal__section-header global-is-stack">
          <Heading
            Tag="h2"
            variant="xl"
            className="evaluation-flow-modal__section-header-title"
          >
            {copy.title}
          </Heading>
          {copy.subtitle && (
            <h3 className="evaluation-flow-modal__section-header-subtitle">
              {copy.subtitle}
            </h3>
          )}
        </header>
        <div className="evaluation-flow-modal__section-intro">
          <p className="evaluation-flow-modal__section-intro-copy">
            {copy.paragraph}
          </p>
          {currentStep === CurrentStep.QUESTIONS &&
            !isCmeEvaluationLoading &&
            cmeEvaluations !== null && (
              <Button
                className="cme-button evaluation-flow-modal__section-intro-button"
                variant="secondary"
                size="small"
                disabled={!hasAnsweredResponse(cmeEvaluationsResponses[0])}
                onClick={() => {
                  dispatch(resetEvaluationResponses());
                }}
                label="Reset Form"
              />
            )}
        </div>
        {submissionState === EvaluationSubmissionStatus.LOADING ? (
          <div>
            Submitting!
            <Loader />
          </div>
        ) : (
          <>
            {currentStep === CurrentStep.QUESTIONS && (
              <div className="evaluation-flow-modal__questions-container global-is-stack global-is-width-wrapper">
                {isCmeEvaluationLoading || cmeEvaluations === null ? (
                  <div>Loading...</div>
                ) : (
                  <CmeEvaluationsQuestions
                    evaluation={cmeEvaluations[0]}
                    evaluationResponse={cmeEvaluationsResponses[0]}
                    updateCmeEvaluationQuestionResponse={(
                      questionId,
                      newOptionIds,
                      textValue
                    ) =>
                      dispatch(
                        updateCmeEvaluationQuestionResponse({
                          questionId,
                          newOptionIds,
                          textValue
                        })
                      )
                    }
                  />
                )}
              </div>
            )}
            {currentStep !== CurrentStep.QUESTIONS && (
              <>
                <div
                  className="evaluation-flow-modal__tab-container"
                  role="tablist"
                  aria-orientation="horizontal"
                >
                  <button
                    className="evaluation-flow-modal__tab"
                    type="button"
                    role="tab"
                    aria-selected={selectedTab === 'credits'}
                    id="credits-tab"
                    aria-controls="credits-panel"
                    onClick={() => changeSelectedTab('credits')}
                    data-bold-width="Credits"
                  >
                    Credits
                  </button>
                  <button
                    className="evaluation-flow-modal__tab"
                    type="button"
                    role="tab"
                    aria-selected={selectedTab === 'episodes'}
                    id="episodes-tab"
                    aria-controls="episodes-panel"
                    onClick={() => changeSelectedTab('episodes')}
                    data-bold-width="Episodes"
                  >
                    Episodes
                  </button>
                </div>
                <div
                  id="credits-panel"
                  role="tabpanel"
                  aria-labelledby="credits-tab"
                  data-tabpanel-is-selected={selectedTab === 'credits'}
                >
                  <TopicCreditsTable credits={evaluationCredits} />
                </div>
                <div
                  id="episodes-panel"
                  role="tabpanel"
                  aria-labelledby="episodes-tab"
                  data-tabpanel-is-selected={selectedTab === 'episodes'}
                >
                  <EpisodeCreditsTable
                    credits={evaluationCredits}
                    isMobile={isMobile}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
