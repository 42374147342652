import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Modal from 'react-modal';
import { useLocation } from 'react-router';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import { ESVideoPlaylistItem } from '../../schema/playlist/ESVideoPlaylistItem';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import HDEpisodePlaylist from './HDEpisodePlaylist';
import HDVideoContent from './HDVideoContent';

import './HDEpisodePlayerGroup.scss';

interface HDEpisodePlayerGroupProps {
  episode: WebEpisode;
  chapter: WebChapter;
  isDesktop: boolean;
  playlist: ESVideoPlaylist | null;
  isSignedIn: boolean;
  isPaidAndActive: boolean;
  isUrgentCare: boolean;
  accountId?: number | null;
}

function getPlaylistItems(
  isEpisodePage: boolean,
  episode: WebEpisode,
  playlist: ESVideoPlaylist | null
): ESVideoPlaylistItem[] {
  if (isEpisodePage || !playlist) {
    // We are inside an episode, return the episode chapters as items
    return episode.chapters.map(chapter => ({
      type: 'chapter',
      url_path: chapter.shortname,
      chapter_media_id: chapter.media[0].id,
      thumbnail: {
        480: {
          width: 480,
          height: 480,
          path: chapter.media[0].image || chapter.thumbnail || ''
        },
        720: {
          width: 720,
          height: 720,
          path: chapter.media[0].image || chapter.thumbnail || ''
        }
      },
      title: chapter.title,
      duration: chapter.media[0].duration,
      uploaded_at: chapter.uploaded_at
        ? chapter.uploaded_at.toDate().toISOString()
        : '', // TODO remove check for falsy once all web episodes are updated
      isFree: chapter.isFree
    }));
  }
  return playlist.items;
}

const HDEpisodePlayerGroup = ({
  episode,
  chapter,
  playlist,
  isDesktop,
  isPaidAndActive,
  isSignedIn,
  isUrgentCare,
  accountId
}: HDEpisodePlayerGroupProps) => {
  const [showPlaylistModal, setShowPlaylistModal] = useState(
    isDesktop && Boolean(!chapter)
  );
  const { pathname } = useLocation();
  const isEpisodePage = pathname.includes('/episode/');
  const items = getPlaylistItems(isEpisodePage, episode, playlist);
  const title = isEpisodePage || !playlist ? episode.title : playlist.title;
  const videoCount =
    isEpisodePage || !playlist ? episode.chapters.length : playlist.video_count;

  // Allow modal to close when another chapter is selected in the playlist
  useEffect(() => setShowPlaylistModal(false), [chapter]);

  return (
    <div
      className={classnames('hd-episode-player-group', {
        'hd-episode-player-group--open-modal': showPlaylistModal
      })}
    >
      <div className="hd-episode-player-group__left-panel">
        <h1 id="skip_to_content" className="hd-chapter-page__chapter-title">
          {chapter.title}
        </h1>
        <HDVideoContent
          isDesktop={isDesktop}
          episode={episode}
          chapter={chapter}
          isEnabled={chapter.isFree || (isSignedIn && isPaidAndActive)}
          isSignedIn={isSignedIn}
          accountId={accountId}
        />
      </div>
      {items.length > 1 &&
        (isDesktop ? (
          <div className="hd-episode-player-group__playlist-container">
            <HDEpisodePlaylist
              title={title}
              videoCount={videoCount}
              items={items}
              isSeeAllVisible={!!playlist}
              chapterShortname={chapter.shortname}
              episodeShortname={episode.shortname}
              isUrgentCare={isUrgentCare}
            />
          </div>
        ) : (
          <>
            <div className="hd-episode-player-group__playlist-button-container">
              <button
                type="button"
                onClick={() => {
                  setShowPlaylistModal(!showPlaylistModal);
                }}
                className="hd-episode-player-group__playlist-button"
              >
                <div className="hd-episode-playlist__header">
                  <h3 className="hd-episode-playlist__header-container">
                    <span className="hd-episode-playlist__header-label">
                      Playlist:
                    </span>
                    <span className="hd-episode-playlist__header-title">
                      {title}
                    </span>
                    <span className="hd-episode-playlist__header-count">
                      {`${videoCount} Videos`}
                    </span>
                  </h3>
                </div>
              </button>
            </div>
            {showPlaylistModal && (
              <Modal
                isOpen={showPlaylistModal}
                className="hd-episode-player-group__playlist-modal"
                overlayClassName="episode-player-group__modal-overlay"
              >
                <div className="hd-episode-player-group__playlist-container">
                  <HDEpisodePlaylist
                    title={title}
                    videoCount={videoCount}
                    items={items}
                    isSeeAllVisible={!!playlist}
                    chapterShortname={chapter.shortname}
                    episodeShortname={episode.shortname}
                    isUrgentCare={isUrgentCare}
                  />
                </div>
                <button
                  type="button"
                  className="hd-episode-player-group__modal-close-button"
                  onClick={() => {
                    setShowPlaylistModal(false);
                  }}
                >
                  Close
                </button>
              </Modal>
            )}
          </>
        ))}
    </div>
  );
};

export default HDEpisodePlayerGroup;
