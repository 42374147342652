import React from 'react';
import { TableCell } from './TableCell';
import { TableBody } from './TableBody';
import { TableHeadingRow } from './TableHeadingRow';
import { AccountCmeCredit } from '../../schema/cme/accountCmeCredit';
import { getCmeCreditsByTopic } from '../../lib/getCmeCreditsByTopic';

import './TopicCreditsTable.scss';

interface TopicCreditsTableProps {
  credits: AccountCmeCredit[];
}

export function TopicCreditsTable({ credits }: TopicCreditsTableProps) {
  const topicCredits = getCmeCreditsByTopic(credits);
  return (
    <table className="topic-credits-table">
      <thead className="topic-credits-table__thead">
        <TableHeadingRow>
          <TableCell scope="col">Topic</TableCell>
          <TableCell className="topic-credits-table__credits" scope="col">
            Credits
          </TableCell>
        </TableHeadingRow>
      </thead>
      <TableBody hasBorderRadius hasBackgroundColor>
        {topicCredits
          .filter(topic => topic.credits > 0)
          .map(topic => (
            <tr key={topic.topic}>
              <TableCell>{topic.topic}</TableCell>
              <TableCell className="topic-credits-table__credits">
                {topic.credits} credits
              </TableCell>
            </tr>
          ))}
      </TableBody>
    </table>
  );
}
