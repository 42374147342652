import React, { useState } from 'react';
import classNames from 'classnames';
import { HDThumbnailDuration } from './HDThumbnailDuration';
import { formatDateMonthYear } from '../../lib/formatDateMonthYear';
import { LinkModernizer } from '../LinkModernizer';

import './HDVideoThumbnail.scss';

interface Props {
  className?: string;
  duration?: number;
  secondsPlayed?: number;
  title?: string;
  imageUrl?: string | null;
  uploadDate?: string | null;
  href?: string;
  isChapterPage?: boolean;
  isSelected?: boolean;
  isSuggestionList?: boolean;
  isFree?: boolean;
  onClick?: () => void;
}

export function HDVideoThumbnail({
  href,
  title,
  className,
  imageUrl,
  duration,
  secondsPlayed,
  uploadDate,
  isChapterPage = false,
  isSuggestionList = false,
  isSelected = false,
  isFree,
  onClick
}: Props) {
  const [isDragging, setIsDragging] = useState(false);
  const playbackProgressInPercent =
    secondsPlayed && duration ? (secondsPlayed / duration) * 100 : 0;
  const progressBarStyles = {
    background: `linear-gradient(to right, #73d6c8 0%, #73d6c8 ${playbackProgressInPercent}%, #100e1099 0%, #100e1099 100%)`
  };

  return (
    <LinkModernizer
      href={href ?? ''}
      to={href ?? ''}
      className={classNames(className, 'hd-video-thumbnail', {
        'hd-video-thumbnail--chapter-page': isChapterPage,
        'hd-video-thumbnail--suggestion-list': isSuggestionList,
        'hd-video-thumbnail--chapter-page-selected': isChapterPage && isSelected
      })}
      onMouseDown={() => {
        setIsDragging(false);
      }}
      onDragStart={e => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onClick={e => {
        if (isDragging) {
          e.preventDefault();
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="hd-video-thumbnail__container">
        <div className="hd-video-thumbnail__poster-frame">
          <div
            className="hd-video-thumbnail__image"
            data-testid="thumbnail-image"
            style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
          />
          <HDThumbnailDuration
            className="hd-video-thumbnail__duration"
            duration={duration}
          />
          {playbackProgressInPercent !== 0 && (
            <div
              className="hd-video-thumbnail__progress-bar"
              style={progressBarStyles}
            />
          )}
        </div>
        <div
          className={classNames('hd-video-thumbnail__description', {
            'hd-video-thumbnail__description--chapter-page': isChapterPage
          })}
        >
          {isChapterPage && isFree && (
            <span className="hd-video-thumbnail__free-badge">free video</span>
          )}
          <h3 className="hd-video-thumbnail__title">
            <span>{title}</span>
          </h3>
          {uploadDate && (
            <h4 className="hd-video-thumbnail__upload-date">
              Uploaded {formatDateMonthYear(uploadDate)}
            </h4>
          )}
        </div>
      </div>
    </LinkModernizer>
  );
}
