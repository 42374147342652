import React from 'react';
import { TableCell } from './TableCell';
import { TableBody } from './TableBody';
import { TableHeadingRow } from './TableHeadingRow';
import { Heading } from '../Heading';
import { AccountCmeCredit } from '../../schema/cme/accountCmeCredit';

import './EpisodeCreditsTable.scss';

interface EpisodeCreditsTableProps {
  credits: AccountCmeCredit[];
  isMobile: boolean;
}

export function EpisodeCreditsTable({
  credits,
  isMobile
}: EpisodeCreditsTableProps) {
  return (
    <table className="episode-credits-table" data-testid="episode-credits">
      <thead className="episode-credits-table__thead">
        <TableHeadingRow className="episode-credits-table__heading">
          <TableCell
            className="episode-credits-table__heading-episodes"
            scope="col"
          >
            Episode{isMobile && 's'}
          </TableCell>
          <TableCell
            className="episode-credits-table__heading-credits"
            scope="col"
          >
            Credits
          </TableCell>
          <TableCell
            className="episode-credits-table__heading-topics"
            scope="col"
          >
            Topics
          </TableCell>
          <TableCell
            className="episode-credits-table__heading-released"
            scope="col"
          >
            Released
          </TableCell>
        </TableHeadingRow>
      </thead>
      <TableBody
        className="episode-credits-table__body"
        hasBorderRadius
        hasBackgroundColor
      >
        {credits.map(credit => (
          <tr key={credit.id} className="episode-credits-table__item">
            <TableCell className="episode-credits-table__item-episode">
              <Heading
                Tag="strong"
                variant="sm"
                className="episode-credits-table__item-episode-title"
              >
                {credit.episode.title}
              </Heading>
            </TableCell>
            <TableCell className="episode-credits-table__item-credits">
              {credit.overallCreditHours}{' '}
              {credit.overallCreditHours === 1 ? 'credit' : 'credits'}
            </TableCell>
            <TableCell className="episode-credits-table__item-topics">
              <strong className="episode-credits-table__item-topics-title">
                Topics
              </strong>
              <ul className="episode-credits-table__item-topics-list">
                {credit.episode.tags.map(tag => (
                  <li key={tag.tag}>{tag.tag}</li>
                ))}
              </ul>
            </TableCell>
            <TableCell className="episode-credits-table__item-released">
              {new Date(credit.releaseDate).toLocaleDateString()}
            </TableCell>
          </tr>
        ))}
      </TableBody>
    </table>
  );
}
