import { track } from '@amplitude/analytics-browser';
import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';

interface ReportOpenEpisodeAnalyticsParams {
  id: number;
  shortname: string;
  title: string;
  type: EpisodeTypeShortname;
  chapterCount: number;
}

export function reportOpenEpisodeAnalytics({
  id,
  shortname,
  title,
  type,
  chapterCount
}: ReportOpenEpisodeAnalyticsParams) {
  track('Open_Episode', {
    id,
    shortname,
    title,
    type,
    chapter_count: chapterCount,
    path: window.location.pathname
  });
}
